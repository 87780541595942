import React from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";

const Stepper = ({ steps, currentStep }) => {
  const isMobile = useMediaQuery("(max-width: 500px)");

  return (
    <Box
      display="flex"
      justifyContent={isMobile ? "flex-start" : "center"}
      alignItems="center"
      py={4}
      mb={2}
      px={2}
      bgcolor="white"
      style={{
        overflowX: "auto",
        whiteSpace: "nowrap",
        scrollbarWidth: "thin",
        minWidth: "100%",
      }}
    >
      {steps.map((step, index) => {
        const isCompleted = index < currentStep;
        const isActive = index === currentStep;
        const backgroundColor = isActive
          ? "#01D4A1"
          : isCompleted
          ? "#092B4F"
          : "#E6E6E6";
        const textColor = isActive || isCompleted ? "#FFF" : "#000";

        return (
          <React.Fragment key={index}>
            <Box display="flex" alignItems="center">
              <Box
                bgcolor={backgroundColor}
                color={textColor}
                width={30}
                height={30}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                mr={1}
                flexShrink={0}
              >
                {index + 1}
              </Box>
              <Typography style={{ flexShrink: 0 }}>{step}</Typography>
            </Box>
            {index < steps.length - 1 && (
              <Box
                mx={2}
                width={40}
                borderTop="1px solid #E0E0E0"
                flexShrink={0}
              />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default Stepper;
