import { Box, Grid } from "@material-ui/core";
import { Loader } from "../components";
import {
  arrayToCommaString,
  calculateInvoiceDue,
  encryptData,
  formatCurrency,
  getPaymentUrl,
  getProcessingFee,
  roundValue,
  stringifyPayfortObject,
} from "../utils";
import { Link, useParams } from "react-router-dom";
import Icons from "../constants/icons";
import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/logo.png";
import { get, getSettings } from "../services/api";
import moment from "moment";
import "./MakeInvoicePayment.css";
import { PROCESSING_FEE_TYPES } from "../constants";
import TenantPayment from "../components/CustomForm/TenantPayment";
import PropertyCompanyHeader from "../components/PropertyCompanyHeader";
import PropertyCompanyFooter from "../components/PropertyCompanyFooter";

const MakeInvoicePayment = () => {
  const params = useParams();
  const [invoice, setInvoice] = useState([]);
  const [amountDue, setAmountDue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getInvoiceDetails();
  }, [params]);

  const getInvoiceDetails = async () => {
    try {
      setIsLoading(true);
      const result = await get.getInvoicePaymentDetails(params);
      setInvoice(result.data);
      let pendingAmount = calculateInvoiceDue(
        result.data?.invoiceDetail?.amount,
        result.data?.invoiceDetail?.payments
      );
      setAmountDue(roundValue(pendingAmount));
      console.log("result.data", result.data?.tenancyContactDetails?.status);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Box
      bgcolor="#F5F7FA"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      <PropertyCompanyHeader
        name={invoice?.invoiceDetail?.property?.propertyAdmin?.name}
        logo={invoice?.propertyAdminDetails?.logo}
      />
      <TenantPayment
        tenantDetails={invoice?.invoiceDetail?.tenancyContactDetail?.user}
        propertyDetails={invoice?.invoiceDetail?.property}
        plot={invoice?.invoiceDetail?.propertyLessedUnit?.plotNumber}
        leaseApprovalStatus={
          invoice?.invoiceDetail?.tenancyContactDetail?.status === "approved"
        }
        invoice={[invoice?.invoiceDetail]}
        propertyAdminDetails={invoice?.propertyAdminDetails}
        navigateFrom=""
      />
      <PropertyCompanyFooter />
    </Box>
  );
};

export default MakeInvoicePayment;
