import { Box, Typography, useMediaQuery } from '@material-ui/core'
import React from 'react'
import { Logo } from '.';

export default function PropertyCompanyFooter() {
    const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box
        mt="auto"
        py={2}
        flexWrap={isMobile ? "wrap" : "nowrap"}
        gridRowGap={isMobile ? "10px" : "0"}
        px={isMobile ? 2 : 5}
        borderTop="1px solid #E0E0E0"
        bgcolor="white"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box height={36} mr={2}>
          <Logo />
        </Box>
        <Typography variant="body2" color="textSecondary">
          Terms & Conditions | Privacy Policy
        </Typography>
      </Box>
  )
}
