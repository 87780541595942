import React from "react";
import {
  amountToCommadString,
  calculateInvoiceDue,
  capitalizeFirstLetter,
  roundValue,
} from "../../utils";
import moment from "moment";
import { Box } from "@material-ui/core";

export default function InvoiceTable({
  invoices,
  selectedInvoice,
  handleSelect,
}) {
  React.useEffect(() => {
    if(invoices.length === 1) {
      handleSelect(0)
    }
  }, [invoices]);
  return (
    <Box overflow={"auto"}>
    <table
      style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}
    >
      <thead>
        <tr style={{ background: "#f4f4f4", textAlign: "left" }}>
          <th style={{ padding: "10px", border: "1px solid #ddd" }}>Select</th>
          <th style={{ padding: "10px", border: "1px solid #ddd" }}>
            Due Date
          </th>
          <th style={{ padding: "10px", border: "1px solid #ddd" }}>Type</th>
          <th
            style={{
              padding: "10px",
              border: "1px solid #ddd",
              whiteSpace: "nowrap",
            }}
          >
            Amount (AED)
          </th>
          <th
            style={{
              padding: "10px",
              border: "1px solid #ddd",
              whiteSpace: "nowrap",
            }}
          >
            Amount Due (AED)
          </th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice, i) => (
          <tr key={invoice.id} style={{ borderBottom: "1px solid #ddd" }}>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "left",
              }}
            >
              <input
                type="radio"
                name="invoice"
                value={invoice.id}
                checked={selectedInvoice === i}
                onChange={() => handleSelect(i)}
              />
            </td>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                whiteSpace: "nowrap",
              }}
            >
              {moment(invoice.dueDate).format("DD-MMM-YYYY")}
            </td>
            <td style={{ padding: "10px", border: "1px solid #ddd" }}>
              {capitalizeFirstLetter(invoice.invoiceType)}
            </td>
            <td style={{ padding: "10px", border: "1px solid #ddd" }}>
              {amountToCommadString(roundValue(invoice.amount))}
            </td>
            <td style={{ padding: "10px", border: "1px solid #ddd" }}>
              {amountToCommadString(
                Math.ceil(
                  roundValue(
                    calculateInvoiceDue(invoice?.amount, invoice?.payments)
                  )
                )
              )}{" "}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </Box>
  );
}
