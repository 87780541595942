import { createTheme } from '@material-ui/core/styles'

const isDev = process.env.NODE_ENV === 'development'

const theme = createTheme({
  palette: {
    // type: isDev ? 'dark' : 'light',
    type: 'light',
    primary: {
      main: '#738C4A',
    },
    secondary: {
      main: '#8c4a73',
    },
  },
})

export default theme
