import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Box } from "@material-ui/core";
import { mdiLogin } from "@mdi/js";
import { toast } from "react-toastify";

import { register as registerForm } from "../constants/forms";

import { register as registerAPI } from "../services/api";

import Form from "../components/Form";
import { Button, Loader, Logo, Section } from "../components";
import { useHistory } from "react-router-dom";

function Register() {
  const history = useHistory();
  const [formConfig, setFormConfig] = useState(null);

  const initializeForm = () => {
    const _formConfig = cloneDeep(registerForm);
    setFormConfig(_formConfig);
  };

  useEffect(() => {
    initializeForm();
  }, []);

  const handleRegister = async ({ name, email, password }) => {
    try {
      const { code } = await registerAPI({ name, email, password });

      if (code.toString() === "200") {
        history.push("/login");
      }
      return toast.success(
        "Account created successfully. Please login to continue"
      );
    } catch (error) {
      return toast.error(error.message);
    }
  };

  if (!formConfig) return <Loader />;
  return (
    <Box
      display="flex"
      minHeight="100vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb={6} height={90}>
        <Logo />
      </Box>

      <Box width="90%" maxWidth={400}>
        <Section
          icon={mdiLogin}
          label="Register"
          cardContent={
            <Box pt={4}>
              <Form
                formConfig={formConfig}
                submitHandler={handleRegister}
                renderPosition="CENTER"
                render={() => (
                  <Box display="flex" justifyContent="flex-end" m={-2} mr={0}>
                    <Button
                      size="small"
                      variant="text"
                      href="/login"
                      routerLink
                    >
                      Already have account? Login
                    </Button>
                  </Box>
                )}
                uiProps={{
                  ctaAreaBoxProps: { pt: 2 },
                  submitButtonText: "Sign Up",
                  showRootError: true,
                }}
              />
            </Box>
          }
        />
      </Box>
    </Box>
  );
}

export default Register;
