import * as Yup from "yup";

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};
export const register = {
  _type: "object",

  name: {
    variant: "outlined",
    type: "name",
    label: "Name",

    defaultValue: "",
    validator: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .required("Name is required"),

    breakpoints: { xs: 12 },
  },
  email: {
    variant: "outlined",
    type: "email",
    label: "Email",

    defaultValue: "",
    validator: Yup.string().email("Invalid email").required("Required"),

    breakpoints: { xs: 12 },
  },
  password: {
    variant: "outlined",
    type: "password",
    label: "Password",

    defaultValue: "",
    validator: Yup.string()
      .required("Please enter a password")
      // check minimum characters
      .min(8, "Password must have at least 8 characters")
      // different error messages for different requirements
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase")),

    breakpoints: { xs: 12 },
  },
  confirmPssword: {
    variant: "outlined",
    type: "password",
    label: "Confirm Password",

    defaultValue: "",
    validator: Yup.string()
      .required("Please re-type your password")
      // use oneOf to match one of the values inside the array.
      // use "ref" to get the value of passwrod.
      .oneOf([Yup.ref("password")], "Passwords does not match"),

    breakpoints: { xs: 12 },
  },
};

export const resetPasswordRequest = {
  _type: "object",

  email: {
    variant: "outlined",
    type: "email",
    label: "Email",

    defaultValue: "",
    validator: Yup.string().email("Invalid email").required("Required"),

    breakpoints: { xs: 12 },
  },
};

export const resetPassword = {
  _type: "object",

  password: {
    variant: "outlined",
    type: "password",
    label: "New Password",

    defaultValue: "",
    validator: Yup.string().min(8).max(64).required("Required"),

    breakpoints: { xs: 12 },
  },
  passwordConfirmation: {
    variant: "outlined",
    type: "password",
    label: "New Password",

    defaultValue: "",
    validator: Yup.string()
      .min(8)
      .max(64)
      .required("Required")
      .test("password-test", "Password do not match", function (value) {
        let { password } = this.parent;
        return value === password;
      }),

    breakpoints: { xs: 12 },
  },
};
