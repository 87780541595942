import { Box, Button, TextField, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import * as Yup from "yup";

export default function PropertyInfo({
  handleNextClick,
  formData,
  setFormData,
}) {
  const validationSchema = Yup.object().shape({
    propertyId: Yup.string().required("Property Id is required"),
    userEmail: Yup.string().email("Please enter a valid email address").required("Email is required"),
  });
  const [errors, setErrors] = React.useState({});

  const handleChange = (e) => {
    setErrors((prev) => ({ ...prev, [e.target.name]: null }));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      handleNextClick(formData);
    } catch (error) {
      const validationErrors = {};
      error?.inner?.forEach((e) => {
        validationErrors[e.path] = e.message;
      });
      setErrors(validationErrors);
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      mx="auto"
      my={4}
      p={4}
      width={isMobile ? "90%" : "400px"}
      bgcolor="#FFF"
      borderRadius="10px"
      border="1px solid #01D4A1"
    >
      <Typography variant="h5" color="#092B4F" style={{fontWeight: "bold"}} >
        Find Your Property
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box display="flex" gridRowGap="14px" my={2} flexDirection="column">
          <TextField
            label="Property Code"
            name="propertyId"
            required
            variant="outlined"
            value={formData.propertyId}
            onChange={handleChange}
            error={errors.propertyId}
            helperText={errors.propertyId}
          />
          <TextField
            label="Email ID"
            name="userEmail"
            required
            variant="outlined"
            value={formData.userEmail}
            onChange={handleChange}
            error={errors.userEmail}
            helperText={errors.userEmail}
          />
        </Box>
        <Box textAlign="center">
          <Button
            variant="contained"
            type="submit"
            style={{
              margin: "0px auto",
              backgroundColor: "#01D4A1",
              color: "#FFF",
              fontWeight: "bold",
              borderRadius: "48px",
              padding: "8px 30px",
            }}
          >
            Next
          </Button>
        </Box>
      </form>
    </Box>
  );
}
