import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import { getSettings, getUserPropertyInvoice, getPropertyAdminDetails } from "../../services/api";
import InvoiceTable from "../Table/InvoiceTable";
import {
  amountToCommadString,
  calculateInvoiceDue,
  encryptData,
  formatCurrency,
  getPaymentUrl,
  getProcessingFee,
  roundValue,
  stringifyPayfortObject,
} from "../../utils";
import { PROCESSING_FEE_TYPES } from "../../constants";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import LeanPaymentGateway from "./LeanPaymentGateway";
import { getPropertyAdminLeanBankDetails } from "../../services/api";

export default function TenantPayment({
  propertyDetails,
  tenantDetails,
  plot,
  leaseApprovalStatus,
  invoice,
  propertyAdminDetails,
  navigateFrom,
}) {
  const [invoices, setInvoices] = React.useState([]);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [settings, setSettings] = React.useState({});
  const [processingFee, setProcessingFee] = React.useState(0);
  const [allowTabby, enableTabbyPayment] = React.useState(true);
  const [leaseApproved, setLeaseApproved] = React.useState(false);
  const [plotNumber, setPlotNumber] = React.useState("");
  const [amountDue, setAmountDue] = React.useState(0);
  // const totalToPay = React.useRef(0);
  const [totalToPay, setTotalToPay] = React.useState(0);
  const tabbyFee = React.useRef(0);
  const [paymentMethodType, setPaymentMethodType] = useState("");
  const [leanModal, setLeanModal] = React.useState(false);
  const [leanEnable, setLeanEnable] = React.useState(true);
  const [propertyAdminShortName, setPropertyAdminShortName] = React.useState("");
  React.useEffect(() => {
    if (amountDue > 0 && paymentMethodType) {
      handlePaymentAmount(amountDue);
      // totalToPay.current = amountDue;
    }
  }, [amountDue, settings, paymentMethodType]);

  React.useEffect(()=>{
    if(propertyDetails.propertyAdminId){
      getPropertyAdminLeanBankDetails(propertyDetails.propertyAdminId)
    .then((response)=>{if(response.data?.paymentDestinationId){setLeanEnable(true)}else{setLeanEnable(false)}})
    .catch((error)=>{console.log("error", error)})
    }
  },[])

  React.useEffect(()=>{
    if(propertyDetails.propertyAdminId){
      getPropertyAdminDetails(propertyDetails.propertyAdminId)
      .then((response)=>{setPropertyAdminShortName(response.propertyAdminDetails.shortName)})
      .catch((error)=>{console.log("error", error)})
    }
  },[])

  const setInvoiceByIdDetails = async () => {
    setLoading(true);
    try {
      let { data: settings } = await getSettings();
      setSettings(settings);
      setPlotNumber(plot);
      setLeaseApproved(leaseApprovalStatus);
      setInvoices(invoice);
    } catch (error) {
      console.log("Error fetching invoices:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      let { data: settings } = await getSettings();
      setSettings(settings);
      const response = await getUserPropertyInvoice({
        userId: tenantDetails?.id,
        propertyId: propertyDetails?.id,
      });
      setInvoices(response.data.invoices);
      setLeaseApproved(response.data.lease_approved);
      setPlotNumber(response.data.apartmentNumber.plotNumber);
    } catch (error) {
      console.log("Error fetching invoices:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (id) => {
    if (!invoices[id]) return;
    const selectedAmount = calculateInvoiceDue(
      invoices[id]?.amount,
      invoices[id]?.payments
    );
    setAmountDue(selectedAmount);
    setSelectedInvoice(id);
  };

  const makeInvoicePayment = () => {
    if (selectedInvoice === null || paymentMethodType === "") {
      return;
    }
    if (paymentMethodType === "lean") {
      setLeanModal(true);
    } else {
      const addedDetails = {
        userId: tenantDetails?.id,
        invoiceId: invoices[selectedInvoice]?.id,
        processingFee: processingFee,
        originalAmount: amountDue,
        vatApplied: 0,
        paymentMode: paymentMethodType,
      };

      const paymentObject = {
        invoiceId: addedDetails.invoiceId,
        amount: totalToPay,
        extra: stringifyPayfortObject(addedDetails),
      };

      const data = encryptData(JSON.stringify(paymentObject));
      const payURL = getPaymentUrl({ data });
      console.log("PAY URL", payURL);
      window.location.href = payURL;
    }
  };

  const handlePaymentAmount = (amount, setting = settings) => {
    if (paymentMethodType === "") return;
    let mainAmount = amount.toString().replaceAll(",", "");
    if (mainAmount <= 5000) {
      enableTabbyPayment(true);
    } else {
      enableTabbyPayment(false);
      // setPaymentMethodType("card");
    }
    let pay = amount;
    if (isNaN(pay)) {
      pay = pay.replaceAll(",", "");
    }
    let total = Number(pay);
    let fee = 0;
    let vatPercent = 0;
    if (paymentMethodType === "tabby" && mainAmount < 5000) {
      fee = pay * 6 * 0.01;
      tabbyFee.current = fee;
    }else if(paymentMethodType === "lean"){
      fee = 0;
    } 
    else if (setting?.processingFeesType === PROCESSING_FEE_TYPES[0]) {
      fee = setting?.processingFees;
    } else {
      fee = pay * setting?.processingFees * 0.01;
    }
    fee = roundValue(fee);
    if (vatPercent === 0) {
      total = Math.ceil(total + fee);
    } else {
      total = Math.ceil(total + fee + roundValue(fee * vatPercent * 0.01));
    }
    setProcessingFee(fee);
    setTotalToPay(total);
    // totalToPay.current = total;
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethodType(e.target.value);
    handlePaymentAmount(amountDue);
  };
  const params = useParams();
  React.useEffect(() => {
    if (!params.id) fetchInvoices();
    else setInvoiceByIdDetails();
  }, [params.id]);
  const isMobile = useMediaQuery("(max-width:600px)");
  if (loading) {
    return <Typography variant="h5">Loading...</Typography>;
  }
  return (
    <Box
      mx="auto"
      my={4}
      p={isMobile ? 2 : 4}
      width={isMobile ? "90%" : "600px"}
      bgcolor="#FFF"
      borderRadius="10px"
      border="1px solid #01D4A1"
    >
      {leanModal? (<>
      <LeanPaymentGateway amount={totalToPay} propertyShortCode={propertyAdminShortName} currency="AED" invoiceId={invoices[selectedInvoice]?.id} navigateFrom={navigateFrom} tenantDetails={tenantDetails} />
      </>):  (
        <>
          <Typography
            variant="h5"
            color="#092B4F"
            style={{ fontWeight: "bold" }}
          >
            Property Details
          </Typography>

          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            gap="10px"
            my={2}
            justifyContent="space-between"
            gridRowGap={isMobile ? "10px" : "0"}
          >
            <Box
              border="1px solid #01D4A1"
              borderRadius={5}
              width={isMobile ? "100%" : "48%"}
              px={3}
              py={1}
            >
              <Typography variant="body1" color="#092B4F" fontWeight="bold">
                Lessor:
              </Typography>
              <Typography variant="body2" color="#092B4F">
                {propertyDetails?.propertyLessor?.lessorName}
              </Typography>
              <Typography variant="body2" color="#092B4F">
                {[propertyDetails?.propertyName, propertyDetails?.buildingName]
                  .filter(Boolean)
                  .join(", ")}
              </Typography>
              <Typography variant="body2" color="#092B4F">
                {[propertyDetails?.landArea, propertyDetails?.city]
                  .filter(Boolean)
                  .join(", ")}
              </Typography>
            </Box>

            <Box
              border="1px solid #01D4A1"
              borderRadius={5}
              width={isMobile ? "100%" : "48%"}
              px={3}
              py={1}
            >
              <Typography variant="body1" color="#092B4F" fontWeight="bold">
                Tenant:
              </Typography>
              <Typography variant="body2" color="#092B4F">
                {tenantDetails?.name}
              </Typography>
              <Typography variant="body2" color="#092B4F">
                {tenantDetails?.email_id}
              </Typography>
              <Typography variant="body2" color="#092B4F">
                {plotNumber}
              </Typography>
            </Box>
          </Box>

          {leaseApproved ? (
            invoices.length === 0 ? (
              <Typography
                variant="h6"
                align="center"
                style={{ marginTop: "20px", color: "#888" }}
              >
                There are no invoices due for payment. Please check back later.
              </Typography>
            ) : (
              <>
                <InvoiceTable
                  handleSelect={handleSelect}
                  invoices={invoices}
                  selectedInvoice={selectedInvoice}
                />
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  gap="14px"
                  gridColumnGap={isMobile ? "0" : "14px"}
                  gridRowGap={isMobile ? "14px" : "0"}
                  justifyContent="space-between"
                  my={2}
                >
                  <Box width="100%">
                    <TextField
                      disabled
                      label="Total Due"
                      variant="outlined"
                      value={
                        selectedInvoice === null
                          ? ""
                          : amountToCommadString(amountDue)
                      }
                      fullWidth
                    />
                  </Box>
                  <Box width="100%">
                    <TextField
                      disabled
                      label="Amount to Pay"
                      variant="outlined"
                      value={
                        selectedInvoice === null
                          ? ""
                          : amountToCommadString(totalToPay)
                      }
                      fullWidth
                    />
                    <Typography
                      style={{ marginTop: "6px" }}
                      variant="body2"
                      color="#092B4F"
                    >
                      Processing Fee:{" "}
                      {paymentMethodType === ""
                        ? ""
                        : paymentMethodType === "tabby"
                        ? `${formatCurrency(Math.ceil(tabbyFee.current))}`
                        : paymentMethodType === "lean"
                        ? "0"
                        : getProcessingFee(settings)}
                    </Typography>
                  </Box>
                </Box>
                <FormControl
                  component="fieldset"
                  style={{ marginBottom: "16px" }}
                >
                  {(propertyAdminDetails?.lean_enabled ||
                    propertyAdminDetails?.tabby_enabled ||
                    propertyAdminDetails?.credit_card_enabled) && (
                    <FormLabel component="legend">Payment Method</FormLabel>
                  )}
                  <Box
                    display="flex"
                    flexDirection={isMobile ? "column" : "row"}
                    flexWrap="wrap"
                    gap="10px"
                  >
                    {propertyAdminDetails?.credit_card_enabled && (
                      <RadioGroup value="card" style={{ flexDirection: "row" }}>
                        <FormControlLabel
                          value="card"
                          checked={paymentMethodType === "card"}
                          control={<Radio />}
                          label="Card Payment"
                          onChange={handlePaymentMethodChange}
                        />
                      </RadioGroup>
                    )}
                    {propertyAdminDetails?.tabby_enabled && (
                      <RadioGroup
                        value="tabby"
                        style={{ flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="tabby"
                          disabled={!allowTabby}
                          checked={paymentMethodType === "tabby"}
                          control={<Radio />}
                          label="Tabby"
                          onChange={handlePaymentMethodChange}
                        />
                      </RadioGroup>
                    )}
                    {propertyAdminDetails?.lean_enabled && leanEnable && (
                      <RadioGroup value="lean" style={{ flexDirection: "row" }}>
                        <FormControlLabel
                          value="lean"
                          checked={paymentMethodType === "lean"}
                          control={<Radio />}
                          label="Instant Transfer"
                          onChange={handlePaymentMethodChange}
                        />
                      </RadioGroup>
                    )}
                  </Box>
                </FormControl>
                <Box textAlign="center">
                  {(propertyAdminDetails?.lean_enabled ||
                  propertyAdminDetails?.tabby_enabled ||
                  propertyAdminDetails?.credit_card_enabled) ? (
                    <Button
                      variant="contained"
                      disabled={
                        selectedInvoice === null || paymentMethodType === ""
                      }
                      style={{
                        margin: "0 auto",
                        backgroundColor: "#01D4A1",
                        color: "#FFF",
                        fontWeight: "bold",
                        borderRadius: "48px",
                        padding: "8px 30px",
                        opacity:
                          selectedInvoice === null || paymentMethodType === ""
                            ? 0.5
                            : 1,
                      }}
                      onClick={makeInvoicePayment}
                    >
                      Pay Now
                    </Button>
                  ) : (
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ marginTop: 2, color: "#888" }}
                    >
                      Payment methods are disabled. Please contact your property
                      manager.
                    </Typography>
                  )}
                </Box>
              </>
            )
          ) : (
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: 2, color: "#888" }}
            >
              Your lease is under approval. Please contact your property
              manager.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}
